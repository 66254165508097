
import { FormikHelpers, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { VacancyCreate } from "../vacancy";
import { Grid, TextField, FormControl, FormControlLabel, FormHelperText, Checkbox, Paper, Box, Button } from "@mui/material";


type VacancyProps = {
  onSubmit: (
    value: VacancyCreate,
    helpers: FormikHelpers<VacancyCreate>
  ) => Promise<boolean>;
  submitting: boolean;
  
};

const CreateVacancyForm = ({
  onSubmit,
  submitting,
  
}: VacancyProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: VacancyCreate,
    helpers: FormikHelpers<VacancyCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/vacancies/create', { replace: true });
    };
  };

  const initialValues: VacancyCreate =  {    title: '',
    description: '',
    positions: 0,
    deadline: '',
    min_experience: '',
    active: false,
}

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    
<>
  <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
      
<Grid item>
  <Paper sx={{ p: 2, pb: 3 }}>
  <Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="title"
    size="small"
    type="text"
    variant="outlined"
    label="Title"
    placeholder=""
    value={formik.values.title}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.title)}
    helperText={formik.errors.title}
  />
</Grid>
</Grid><Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="description"
    size="small"
    type="text"
    variant="outlined"
    label="Description"
    placeholder=""
    value={formik.values.description}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.description)}
    helperText={formik.errors.description}
    multiline
    rows={4}
  />
</Grid>
</Grid><Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="positions"
    size="small"
    type="number"
    variant="outlined"
    label="Positions"
    placeholder=""
    value={formik.values.positions}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.positions)}
    helperText={formik.errors.positions}
  />
</Grid>

<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="deadline"
    size="small"
    type="date"
    variant="outlined"
    label="Date"
    placeholder=""
    value={formik.values.deadline}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.deadline)}
    helperText={formik.errors.deadline}
  />
</Grid>
</Grid><Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="min_experience"
    size="small"
    type="text"
    variant="outlined"
    label="Min experience"
    placeholder=""
    value={formik.values.min_experience}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.min_experience)}
    helperText={formik.errors.min_experience}
  />
</Grid>

<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <FormControl error>
    <FormControlLabel
      control={<Checkbox name="active" checked={formik.values.active} onChange={formik.handleChange} />}
      label="Active"
    />
    { Boolean(formik.errors.active) && <FormHelperText>{formik.errors.active}</FormHelperText> }
  </FormControl>
</Grid>
</Grid>

    <Grid container sx={{ mt: 2 }}>
      <Button
        type="submit"
        variant="contained"
        disabled={submitting}
        size="small"
      >
        {submitting ? 'Saving' : 'Save'}
      </Button>
    </Grid>
  </Paper>
</Grid>

      </Grid>
    </form>
  </Box>
</>
  
  );
};

export default CreateVacancyForm;
