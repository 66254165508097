import { Grid } from '@mui/material';
import { useState } from 'react';
import { getMediaUrl } from '../../utils/image';
import { Media } from '../../utils/types';

const ImagePreview = ({
  media,
  alt = 'preview',
}: {
  media: Media[];
  alt?: string;
}) => {
  const [showPreview, setShowPreview] = useState(false);

  return (
    <>
      <Grid sx={{ position: 'relative', width: 50 }}>
        <img
          src={getMediaUrl((media || [])[0])}
          width="50px"
          height="50px"
          style={{ borderRadius: '25px', objectFit: 'cover' }}
          alt={alt}
          onMouseEnter={() => setShowPreview(true)}
          onMouseLeave={() => setShowPreview(false)}
        />

        <Grid
          sx={{
            position: 'absolute',
            top: '50%',
            right: -10,
            width: 200,
            transform: 'translate(100%, -50%)',
            opacity: showPreview ? 1 : 0.5,
            visibility: showPreview ? 'visible' : 'hidden',
            transition: 'all 0.2s ease',
          }}
        >
          <img src={getMediaUrl((media || [])[0])} width="200px" alt={alt} />
        </Grid>
      </Grid>
    </>
  );
};

export default ImagePreview;
