import { Grid, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Layout from "../../../core/ui/layout/Layout";
import { useSendApiData } from "../../../core/hooks/useSendApiData";
import { toastError, toastMessage } from "../../../core/utils/ui/alert";
import { parseValidationErrors } from "../../../core/utils/validation";
import useFetchApiData from "../../../core/hooks/useFetchApiData";
import Loader from "../../../core/ui/utility/Loader";
import { DocumentEdit, Document } from "../document";
import EditDocumentForm from "../components/EditDocumentForm";
import ReturnButton from "../../../core/ui/utility/ReturnButton";
import { parseFormQuery } from "../../../core/utils/utility";

const EditDocumentContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [document, setDocument] = useState<Document | null>(null);

  const fetchDocument = () =>
    fetchData(`documents/${id}`, {
      onSuccess: (data: Document) => {
        setDocument(data);
      },
    });

  useEffect(() => {
    fetchDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: DocumentEdit,
    { setFieldError }: FormikHelpers<DocumentEdit>
  ) => {
    const formData: any = parseFormQuery(values, [])

    await callApi({
      endpoint: `auth-corha96/documents/${id}?_method=PATCH`,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchDocument();
        toastMessage('Document Edited');
      },
    });
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/documents" />}>
      <Loader loading={loading || !document}>
        <Grid sx={{ p: 2 }}>  
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit Document</Typography>
          </Grid>
          <EditDocumentForm document={document!} onSubmit={handleSubmit} submitting={submitting} />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditDocumentContainer;
