import { Link } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { getMediaUrl } from '../../utils/image';
import { Media } from '../../utils/types';

type FileContainerProps = {
  media: Media | null;
};

const FileContainer = ({ media }: FileContainerProps) => {
  return (
    <>
      {media && (
        <a href={getMediaUrl(media)} target="_blank" rel="noreferrer">
          <Grid container flexDirection="row">
            <Grid
              item
              sx={{ border: '1px solid', p: 1, my: 1 }}
              display="flex"
              alignItems="center"
            >
              <Typography sx={{ mr: 2 }}>{media?.name}</Typography>
              <Link />
            </Grid>
          </Grid>
        </a>
      )}
    </>
  );
};

export default FileContainer;
