import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { PublicationCreate } from '../publication';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Box,
  Paper,
  Button,
} from '@mui/material';

type PublicationProps = {
  onSubmit: (
    value: PublicationCreate,
    helpers: FormikHelpers<PublicationCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreatePublicationForm = ({ onSubmit, submitting }: PublicationProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: PublicationCreate,
    helpers: FormikHelpers<PublicationCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/publications/create', { replace: true });
    }
  };

  const initialValues: PublicationCreate = {
    title: '',
    tag: 'general',
    date: '',
    description: '',
    image: null,
    document: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder=""
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl
                      sx={{ minWidth: 120 }}
                      fullWidth
                      error={Boolean(formik.errors.tag)}
                    >
                      <InputLabel>Category</InputLabel>
                      <Select
                        value={formik.values.tag}
                        size="small"
                        label="Category"
                        onChange={formik.handleChange}
                        name="tag"
                      >
                        <MenuItem value="general">General</MenuItem>
                        <MenuItem value="std">STD</MenuItem>
                        <MenuItem value="contraception">Contraception</MenuItem>
                        <MenuItem value="children">Children</MenuItem>
                      </Select>
                      {Boolean(formik.errors.tag) && (
                        <FormHelperText>{formik.errors.tag}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="date"
                      size="small"
                      type="date"
                      variant="outlined"
                      label="Date"
                      placeholder=""
                      value={formik.values.date}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.date)}
                      helperText={formik.errors.date}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="description"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Description"
                      placeholder=""
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.description)}
                      helperText={formik.errors.description}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Cover Image
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'image',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="image"
                        accept="image/*"
                      />
                    </Box>
                    {formik.touched.image && formik.errors.image && (
                      <FormHelperText error>
                        {formik.errors.image}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Document
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'document',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="document"
                        accept="application/pdf"
                      />
                    </Box>
                    {formik.touched.document && formik.errors.document && (
                      <FormHelperText error>
                        {formik.errors.document}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreatePublicationForm;
