import { Grid, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import Layout from "../../../core/ui/layout/Layout";
import { useSendApiData } from "../../../core/hooks/useSendApiData";
import { toastError, toastMessage } from "../../../core/utils/ui/alert";
import { parseValidationErrors } from "../../../core/utils/validation";
import { EventModelCreate } from "../eventModel";
import CreateEventModelForm from "../components/CreateEventModelForm";
import ReturnButton from "../../../core/ui/utility/ReturnButton";
import { parseFormQuery } from "../../../core/utils/utility";

const CreateEventModelContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();
  

  const handleSubmit = async (
    values: EventModelCreate,
    { setFieldError }: FormikHelpers<EventModelCreate>
  ) => {
    let success = false;

    const formData: any = parseFormQuery(values, ["date"])

    await callApi({
      endpoint: 'auth-corha96/events',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('EventModel Created');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/events" />}>
      
      <Grid sx={{ p: 2 }}>
        <Grid container sx={{ mb: 2, px: 1 }}>
          <Typography variant="h5">Add EventModel</Typography>
        </Grid>
        <CreateEventModelForm  onSubmit={handleSubmit} submitting={submitting} />
      </Grid>
      
    </Layout>
  );
};

export default CreateEventModelContainer;
