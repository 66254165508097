import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import { TenderCreate } from '../tender';
import CreateTenderForm from '../components/CreateTenderForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';

const CreateTenderContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();

  const handleSubmit = async (
    values: TenderCreate,
    { setFieldError }: FormikHelpers<TenderCreate>
  ) => {
    let success = false;

    await callApi({
      endpoint: 'auth-corha96/tenders',
      data: values,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('Tender Created');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/tenders" />}>
      <Grid sx={{ p: 2 }}>
        <Grid container sx={{ mb: 2, px: 1 }}>
          <Typography variant="h5">Add Tender</Typography>
        </Grid>
        <CreateTenderForm onSubmit={handleSubmit} submitting={submitting} />
      </Grid>
    </Layout>
  );
};

export default CreateTenderContainer;
