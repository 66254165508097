import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { VideoCreate } from '../video';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Paper,
  Box,
  Button,
} from '@mui/material';

type VideoProps = {
  onSubmit: (
    value: VideoCreate,
    helpers: FormikHelpers<VideoCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreateVideoForm = ({ onSubmit, submitting }: VideoProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: VideoCreate,
    helpers: FormikHelpers<VideoCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/videos/create', { replace: true });
    }
  };

  const initialValues: VideoCreate = {
    title: '',
    date: '',
    tag: 'general',
    link: 'https://www.youtube.com/embed/',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={1}
            sx={{ p: 2, pb: 3, width: 400 }}
            component={Paper}
          >
            <Grid
              container
              justifyContent="space-between"
              spacing={1}
              sx={{ mt: 2, width: '100%' }}
            >
              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="title"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Title"
                  placeholder=""
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.title)}
                  helperText={formik.errors.title}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              spacing={1}
              sx={{ mt: 2 }}
            >
              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="date"
                  size="small"
                  type="date"
                  variant="outlined"
                  label="Date"
                  placeholder=""
                  value={formik.values.date}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.date)}
                  helperText={formik.errors.date}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              spacing={1}
              sx={{ mt: 2 }}
            >
              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <FormControl
                  sx={{ minWidth: 120 }}
                  fullWidth
                  error={Boolean(formik.errors.tag)}
                >
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={formik.values.tag}
                    size="small"
                    label="Category"
                    onChange={formik.handleChange}
                    name="tag"
                  >
                    <MenuItem value="general">General</MenuItem>
                    <MenuItem value="std">STD</MenuItem>
                    <MenuItem value="contraception">Contraception</MenuItem>
                    <MenuItem value="children">Children</MenuItem>
                  </Select>
                  {Boolean(formik.errors.tag) && (
                    <FormHelperText>{formik.errors.tag}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              spacing={1}
              sx={{ mt: 2 }}
            >
              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="link"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Embed Link(video id)"
                  placeholder=""
                  value={formik.values.link}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.link)}
                  helperText={formik.errors.link}
                />
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                disabled={submitting}
                size="small"
              >
                {submitting ? 'Saving' : 'Save'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateVideoForm;
