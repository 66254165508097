const apiAddress = 'https://api.corhaethiopia.org.et';

const constants = {
  apiAddress,
  apiUrl: `${apiAddress}/api`,
  mediaUrl: `${apiAddress}/media`,
  authUrl: 'auth-corha96',
  keys: {
    user: 'user',
  },
  prefixes: {
    sorting: 'sorting',
    visibility: 'visibility',
    density: 'density',
    rowsPerPage: 'rows_per_page',
  },
};

export default constants;
