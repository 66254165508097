import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../features/auth/containers/PrivateRoute';
import LoginPage from '../../features/auth/pages/LoginPage';
import ProfilePage from '../../features/auth/pages/ProfilePage';
import HomePage from '../../features/HomePage';
import ListContactUsPage from '../../features/contactUs/pages/ListContactUsPage';
import ListPublicationPage from '../../features/publication/pages/ListPublicationPage';
import CreatePublicationPage from '../../features/publication/pages/CreatePublicationPage';
import EditPublicationPage from '../../features/publication/pages/EditPublicationPage';
import ListGalleryPage from '../../features/gallery/pages/ListGalleryPage';
import CreateGalleryPage from '../../features/gallery/pages/CreateGalleryPage';
import EditGalleryPage from '../../features/gallery/pages/EditGalleryPage';
import ListOtherSayPage from '../../features/otherSay/pages/ListOtherSayPage';
import CreateOtherSayPage from '../../features/otherSay/pages/CreateOtherSayPage';
import EditOtherSayPage from '../../features/otherSay/pages/EditOtherSayPage';
import ListOrganizationPage from '../../features/organization/pages/ListOrganizationPage';
import CreateOrganizationPage from '../../features/organization/pages/CreateOrganizationPage';
import EditOrganizationPage from '../../features/organization/pages/EditOrganizationPage';
import ListEventModelPage from '../../features/eventModel/pages/ListEventModelPage';
import CreateEventModelPage from '../../features/eventModel/pages/CreateEventModelPage';
import EditEventModelPage from '../../features/eventModel/pages/EditEventModelPage';
import ListBlogPage from '../../features/blog/pages/ListBlogPage';
import CreateBlogPage from '../../features/blog/pages/CreateBlogPage';
import EditBlogPage from '../../features/blog/pages/EditBlogPage';
import ListProjectPage from '../../features/project/pages/ListProjectPage';
import CreateProjectPage from '../../features/project/pages/CreateProjectPage';
import EditProjectPage from '../../features/project/pages/EditProjectPage';
import ListVacancyPage from '../../features/vacancy/pages/ListVacancyPage';
import CreateVacancyPage from '../../features/vacancy/pages/CreateVacancyPage';
import EditVacancyPage from '../../features/vacancy/pages/EditVacancyPage';
import ListTenderPage from '../../features/tender/pages/ListTenderPage';
import CreateTenderPage from '../../features/tender/pages/CreateTenderPage';
import EditTenderPage from '../../features/tender/pages/EditTenderPage';
import ListTeamPage from '../../features/team/pages/ListTeamPage';
import CreateTeamPage from '../../features/team/pages/CreateTeamPage';
import EditTeamPage from '../../features/team/pages/EditTeamPage';
import ListVideoPage from '../../features/video/pages/ListVideoPage';
import CreateVideoPage from '../../features/video/pages/CreateVideoPage';
import EditVideoPage from '../../features/video/pages/EditVideoPage';
import ListDocumentPage from '../../features/document/pages/ListDocumentPage';
import CreateDocumentPage from '../../features/document/pages/CreateDocumentPage';
import EditDocumentPage from '../../features/document/pages/EditDocumentPage';
import ListVolunteersPage from '../../features/volunteers/pages/ListVolunteersPage';
import ListNewsPage from '../../features/news/pages/ListNewsPage';
import CreateNewsPage from '../../features/news/pages/CreateNewsPage';
import EditNewsPage from '../../features/news/pages/EditNewsPage';
import ListSubscriptionPage from '../../features/subscription/pages/ListSubscriptionPage';

const RouteManager = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />

        <Route
          path="/contact"
          element={
            <PrivateRoute>
              <ListContactUsPage />
            </PrivateRoute>
          }
        />

        {/* Publication  */}
        <Route
          path="/publications"
          element={
            <PrivateRoute>
              <ListPublicationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/publications/create"
          element={
            <PrivateRoute>
              <CreatePublicationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/publications/edit/:id"
          element={
            <PrivateRoute>
              <EditPublicationPage />
            </PrivateRoute>
          }
        />

        {/* Gallery  */}
        <Route
          path="/galleries"
          element={
            <PrivateRoute>
              <ListGalleryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/galleries/create"
          element={
            <PrivateRoute>
              <CreateGalleryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/galleries/edit/:id"
          element={
            <PrivateRoute>
              <EditGalleryPage />
            </PrivateRoute>
          }
        />

        {/* Other Say  */}
        <Route
          path="/other-says"
          element={
            <PrivateRoute>
              <ListOtherSayPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/other-says/create"
          element={
            <PrivateRoute>
              <CreateOtherSayPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/other-says/edit/:id"
          element={
            <PrivateRoute>
              <EditOtherSayPage />
            </PrivateRoute>
          }
        />

        {/* Organization  */}
        <Route
          path="/organizations"
          element={
            <PrivateRoute>
              <ListOrganizationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/organizations/create"
          element={
            <PrivateRoute>
              <CreateOrganizationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/organizations/edit/:id"
          element={
            <PrivateRoute>
              <EditOrganizationPage />
            </PrivateRoute>
          }
        />

        {/* Event  */}
        <Route
          path="/events"
          element={
            <PrivateRoute>
              <ListEventModelPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/create"
          element={
            <PrivateRoute>
              <CreateEventModelPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/edit/:id"
          element={
            <PrivateRoute>
              <EditEventModelPage />
            </PrivateRoute>
          }
        />

        {/* Blog  */}
        <Route
          path="/blogs"
          element={
            <PrivateRoute>
              <ListBlogPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/blogs/create"
          element={
            <PrivateRoute>
              <CreateBlogPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/blogs/edit/:id"
          element={
            <PrivateRoute>
              <EditBlogPage />
            </PrivateRoute>
          }
        />

        {/* Project  */}
        <Route
          path="/projects"
          element={
            <PrivateRoute>
              <ListProjectPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects/create"
          element={
            <PrivateRoute>
              <CreateProjectPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects/edit/:id"
          element={
            <PrivateRoute>
              <EditProjectPage />
            </PrivateRoute>
          }
        />

        {/* Vacancy  */}
        <Route
          path="/vacancies"
          element={
            <PrivateRoute>
              <ListVacancyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/vacancies/create"
          element={
            <PrivateRoute>
              <CreateVacancyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/vacancies/edit/:id"
          element={
            <PrivateRoute>
              <EditVacancyPage />
            </PrivateRoute>
          }
        />

        {/* Tender  */}
        <Route
          path="/tenders"
          element={
            <PrivateRoute>
              <ListTenderPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/tenders/create"
          element={
            <PrivateRoute>
              <CreateTenderPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/tenders/edit/:id"
          element={
            <PrivateRoute>
              <EditTenderPage />
            </PrivateRoute>
          }
        />
        {/* Team  */}
        <Route
          path="/teams"
          element={
            <PrivateRoute>
              <ListTeamPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/teams/create"
          element={
            <PrivateRoute>
              <CreateTeamPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/teams/edit/:id"
          element={
            <PrivateRoute>
              <EditTeamPage />
            </PrivateRoute>
          }
        />

        {/* Video  */}
        <Route
          path="/videos"
          element={
            <PrivateRoute>
              <ListVideoPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/videos/create"
          element={
            <PrivateRoute>
              <CreateVideoPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/videos/edit/:id"
          element={
            <PrivateRoute>
              <EditVideoPage />
            </PrivateRoute>
          }
        />

        {/* Document  */}
        <Route
          path="/documents"
          element={
            <PrivateRoute>
              <ListDocumentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/documents/create"
          element={
            <PrivateRoute>
              <CreateDocumentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/documents/edit/:id"
          element={
            <PrivateRoute>
              <EditDocumentPage />
            </PrivateRoute>
          }
        />

        {/* News  */}
        <Route
          path="/news"
          element={
            <PrivateRoute>
              <ListNewsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/news/create"
          element={
            <PrivateRoute>
              <CreateNewsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/news/edit/:id"
          element={
            <PrivateRoute>
              <EditNewsPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/volunteers"
          element={
            <PrivateRoute>
              <ListVolunteersPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/subscriptions"
          element={
            <PrivateRoute>
              <ListSubscriptionPage />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<>Not Found page</>} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteManager;
