export const parseQuery = ({
  page,
  rowsPerPage,
  sortField,
  sortOp,
  filter,
}: Record<string, any>) => {
  let parsed = '?';

  if (rowsPerPage) {
    parsed += `limit=${rowsPerPage}&page=${page}&`;
  }
  if (sortField) {
    parsed += `sort_field=${sortField}&sort_op=${sortOp || 'asc'}&`;
  }
  if (filter) {
    parsed += `filter=${JSON.stringify(filter)}`;
  }

  if (parsed.endsWith('&')) {
    parsed = parsed.substring(0, parsed.length - 1);
  }

  return parsed;
};

export const richTextModules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
    ['link', 'image'],
  ],
};

export const parseFormQuery = (
  values: any,
  dateFields: string[] = [],
  asJson = false,
  nullableFields: string[] = []
) => {
  const formData: any = new FormData();
  const formValues = { ...values };

  dateFields.forEach((field) => {
    if (
      formValues[field] !== null &&
      formValues[field] !== undefined &&
      formValues[field] !== ''
    ) {
      formValues[field] = formValues[field]
        .toISOString()
        .substring(0, 10) as unknown as Date;
    }
  });
  // console.log(formValues);
  if (asJson) {
    return formValues;
  }

  Object.entries(formValues).forEach(([key, val]) => {
    // console.log(key, val);
    if (val !== null && val !== undefined && val !== '') {
      if (Array.isArray(val)) {
        val.forEach((item) => {
          formData.append(`${key}[]`, item);
        });
      } else {
        formData.append(key, val as any);
      }
    }
  });
  nullableFields.forEach((field) => {
    formData.append(field, formValues[field] || null);
  });

  // console.log(JSON.stringify(Object.fromEntries(formData)));

  return formData;
};
