import { Grid, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Layout from "../../../core/ui/layout/Layout";
import { useSendApiData } from "../../../core/hooks/useSendApiData";
import { toastError, toastMessage } from "../../../core/utils/ui/alert";
import { parseValidationErrors } from "../../../core/utils/validation";
import useFetchApiData from "../../../core/hooks/useFetchApiData";
import Loader from "../../../core/ui/utility/Loader";
import { ProjectEdit, Project } from "../project";
import EditProjectForm from "../components/EditProjectForm";
import ReturnButton from "../../../core/ui/utility/ReturnButton";
import { parseFormQuery } from "../../../core/utils/utility";

const EditProjectContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [project, setProject] = useState<Project | null>(null);

  const fetchProject = () =>
    fetchData(`projects/${id}`, {
      onSuccess: (data: Project) => {
        setProject(data);
      },
    });

  useEffect(() => {
    fetchProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: ProjectEdit,
    { setFieldError }: FormikHelpers<ProjectEdit>
  ) => {
    const formData: any = parseFormQuery(values, [])

    await callApi({
      endpoint: `auth-corha96/projects/${id}?_method=PATCH`,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchProject();
        toastMessage('Project Edited');
      },
    });
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/projects" />}>
      <Loader loading={loading || !project}>
        <Grid sx={{ p: 2 }}>  
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit Project</Typography>
          </Grid>
          <EditProjectForm project={project!} onSubmit={handleSubmit} submitting={submitting} />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditProjectContainer;
