import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import DeleteAction from '../../../core/ui/utility/DeleteAction';
import FileContainer from '../../../core/ui/utility/FileContainer';
import { toastError } from '../../../core/utils/ui/alert';
import { TenderBid } from '../tender';

type BidProps = {
  bids: TenderBid[];
  fetchTender: () => void;
};

const TenderBidList = ({ bids, fetchTender }: BidProps) => {
  const { callApi } = useSendApiData();

  return (
    <>
      <Box sx={{ maxWidth: 1400, mt: 4 }}>
        <Typography variant="h5">Tender Bids</Typography>
        <TableContainer component={Paper} sx={{ overflowX: 'auto', mt: 2 }}>
          <Table aria-label="custom pagination table" size="small">
            <TableHead sx={{ background: 'rgb(243,243,242)' }}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Bid Document</TableCell>
                <TableCell>Date Applied</TableCell>
                <TableCell align="right" sx={{ width: 48 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bids.map((application) => (
                <TableRow key={application.id}>
                  <TableCell size="small">
                    {application.fname} {application.lname}
                  </TableCell>
                  <TableCell size="small">{application.email}</TableCell>
                  <TableCell size="small">{application.phone}</TableCell>
                  <TableCell size="small">{application.company}</TableCell>
                  <TableCell size="small" sx={{ maxWidth: 400 }}>
                    <FileContainer media={application.media[0]} />
                  </TableCell>
                  <TableCell>
                    {DateTime.fromISO(application.created_at).toLocaleString(
                      DateTime.DATE_MED
                    )}
                  </TableCell>
                  <TableCell align="right" sx={{ width: 48 }}>
                    <DeleteAction
                      message="Are you sure you want to remove entry?"
                      onDelete={async (e) =>
                        callApi({
                          endpoint: `auth-corha96/tender-bids/${application.id}`,
                          method: 'delete',
                          data: {},
                          onSuccess: () => {
                            fetchTender();
                          },
                          onError: toastError,
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default TenderBidList;
