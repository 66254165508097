import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { OrganizationCreate } from '../organization';
import {
  Grid,
  TextField,
  Box,
  FormHelperText,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

type OrganizationProps = {
  onSubmit: (
    value: OrganizationCreate,
    helpers: FormikHelpers<OrganizationCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreateOrganizationForm = ({
  onSubmit,
  submitting,
}: OrganizationProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: OrganizationCreate,
    helpers: FormikHelpers<OrganizationCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/organizations/create', { replace: true });
    }
  };

  const initialValues: OrganizationCreate = {
    name: '',
    description: '',
    image: null,
    link: '',
    year_established: '',
    country: '',
    category: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, width: '100%', maxWidth: 400 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Paper sx={{ p: 2, pb: 3, width: '100%' }}>
              <Grid
                container
                justifyContent="space-between"
                spacing={1}
                sx={{ mt: 2 }}
              >
                <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                  <TextField
                    fullWidth
                    name="name"
                    size="small"
                    type="text"
                    variant="outlined"
                    label="Name"
                    placeholder=""
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.name)}
                    helperText={formik.errors.name}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                spacing={1}
                sx={{ mt: 2 }}
              >
                <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                  <TextField
                    fullWidth
                    name="description"
                    size="small"
                    type="text"
                    variant="outlined"
                    label="Description"
                    placeholder=""
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.description)}
                    helperText={formik.errors.description}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                spacing={1}
                sx={{ mt: 2 }}
              >
                <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                  <TextField
                    fullWidth
                    name="country"
                    size="small"
                    type="text"
                    variant="outlined"
                    label="Country"
                    placeholder=""
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.country)}
                    helperText={formik.errors.country}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                spacing={1}
                sx={{ mt: 2 }}
              >
                <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                  <FormControl
                    sx={{ minWidth: 120 }}
                    fullWidth
                    error={Boolean(formik.errors.category)}
                  >
                    <InputLabel>Category</InputLabel>
                    <Select
                      value={formik.values.category}
                      size="small"
                      label="Category"
                      onChange={formik.handleChange}
                      name="category"
                    >
                      <MenuItem value="Local CSO">Local CSO</MenuItem>
                      <MenuItem value="Foreign CSO">Foreign CSO</MenuItem>
                    </Select>
                    {Boolean(formik.errors.category) && (
                      <FormHelperText>{formik.errors.category}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                spacing={1}
                sx={{ mt: 2 }}
              >
                <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                  <TextField
                    fullWidth
                    name="year_established"
                    size="small"
                    type="text"
                    variant="outlined"
                    label="Year Established"
                    placeholder=""
                    value={formik.values.year_established}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.year_established)}
                    helperText={formik.errors.year_established}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                spacing={1}
                sx={{ mt: 2 }}
              >
                <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                  <TextField
                    fullWidth
                    name="link"
                    size="small"
                    type="text"
                    variant="outlined"
                    label="Link"
                    placeholder=""
                    value={formik.values.link}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.link)}
                    helperText={formik.errors.link}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                spacing={1}
                sx={{ mt: 2 }}
              >
                <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                  <Grid item xs={12}>
                    Cover Image
                  </Grid>
                  <Box>
                    <input
                      type="file"
                      onChange={({ target: { files } }) => {
                        formik.setFieldValue(
                          'image',
                          files && files.length ? files[0] : null
                        );
                      }}
                      name="image"
                    />
                  </Box>
                  {formik.touched.image && formik.errors.image && (
                    <FormHelperText error>{formik.errors.image}</FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid container sx={{ mt: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  size="small"
                >
                  {submitting ? 'Saving' : 'Save'}
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateOrganizationForm;
