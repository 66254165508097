import axios from 'axios';
import authService from '../../features/auth/service/authService';
import constants from './constants';

export const uploadFile = async (endpoint: string, data: FormData) => {
  try {
    const res = await axios.post(`${constants.apiUrl}/${endpoint}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: `Bearer ${authService.getToken()}`,
      },
    });
    return [res.data, null];
  } catch (err) {
    console.log('Upload error', err);
    return [null, (err as any).message || 'Error uploading, try again'];
  }
};
