import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Loader from '../../../core/ui/utility/Loader';
import { TenderEdit, Tender } from '../tender';
import EditTenderForm from '../components/EditTenderForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import TenderBidList from '../components/TenderBidList';

const EditTenderContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [tender, setTender] = useState<Tender | null>(null);

  const fetchTender = () =>
    fetchData(`auth-corha96/tenders/with-bids/${id}`, {
      onSuccess: (data: Tender) => {
        setTender(data);
      },
    });

  useEffect(() => {
    fetchTender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: TenderEdit,
    { setFieldError }: FormikHelpers<TenderEdit>
  ) => {
    await callApi({
      endpoint: `auth-corha96/tenders/${id}`,
      method: 'patch',
      data: values,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchTender();
        toastMessage('Tender Edited');
      },
    });
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/tenders" />}>
      <Loader loading={loading || !tender}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit Tender</Typography>
          </Grid>
          <EditTenderForm
            tender={tender!}
            onSubmit={handleSubmit}
            submitting={submitting}
          />

          <TenderBidList bids={tender?.bids || []} fetchTender={fetchTender} />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditTenderContainer;
