import {
  Assignment,
  Business,
  CalendarToday,
  DocumentScanner,
  Email,
  Feed,
  Group,
  Image,
  Message,
  Newspaper,
  Person,
  PlayCircleFilled,
  RssFeed,
  Work,
} from '@mui/icons-material';
import { Divider, Grid, List, Toolbar, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SidebarLink from './SidebarLink';

const Sidebar = () => {
  const location = useLocation();

  return (
    <>
      <div>
        <Toolbar variant="dense" />
        <Divider />
        <List sx={{ p: 1 }}>
          <Grid
            container
            flexDirection="column"
            justifyContent="space-between"
            sx={{}}
          >
            <Grid item>
              <Typography variant="subtitle1" fontSize="12px" fontWeight="bold">
                COLLECTION TYPES
              </Typography>
              <SidebarLink
                to="/blogs"
                label="Blog"
                active={location.pathname.startsWith('/blogs')}
                icon={<RssFeed />}
              />
              <SidebarLink
                to="/documents"
                label="Document"
                icon={<DocumentScanner />}
                active={location.pathname.startsWith('/documents')}
              />
              <SidebarLink
                to="/events"
                label="Events"
                active={location.pathname.startsWith('/events')}
                icon={<CalendarToday />}
              />
              <SidebarLink
                to="/galleries"
                label="Gallery"
                active={location.pathname.startsWith('/galleries')}
                icon={<Image />}
              />
              <SidebarLink
                to="/news"
                label="News"
                icon={<Newspaper />}
                active={location.pathname.startsWith('/news')}
              />
              <SidebarLink
                to="/organizations"
                label="Organization"
                active={location.pathname.startsWith('/organizations')}
                icon={<Business />}
              />
              <SidebarLink
                to="/other-says"
                label="Others Say"
                active={location.pathname.startsWith('/other-says')}
                icon={<Person />}
              />
              <SidebarLink
                to="/projects"
                label="Project"
                active={location.pathname.startsWith('/projects')}
                icon={<Assignment />}
              />
              <SidebarLink
                to="/publications"
                label="Publication"
                active={location.pathname.startsWith('/publications')}
                icon={<Feed />}
              />
              <SidebarLink
                to="/tenders"
                label="Tender"
                active={location.pathname.startsWith('/tenders')}
              />
              <SidebarLink
                to="/teams"
                label="Team"
                active={location.pathname.startsWith('/teams')}
                icon={<Group />}
              />
              <SidebarLink
                to="/vacancies"
                label="Vacancy"
                active={location.pathname.startsWith('/vacancies')}
                icon={<Work />}
              />
              <SidebarLink
                to="/videos"
                label="Video"
                active={location.pathname.startsWith('/videos')}
                icon={<PlayCircleFilled />}
              />
            </Grid>

            <Grid item>
              <Typography
                variant="subtitle1"
                fontSize="12px"
                fontWeight="bold"
                sx={{ mt: 2 }}
              >
                Forms
              </Typography>
              <SidebarLink
                to="/contact"
                label="Contact Us"
                active={location.pathname.startsWith('/contact')}
                icon={<Message />}
              />
              <SidebarLink
                to="/subscriptions"
                label="Subscription"
                active={location.pathname.startsWith('/subscriptions')}
                icon={<Email />}
              />
              <SidebarLink
                to="/volunteers"
                label="Volunteers"
                active={location.pathname.startsWith('/volunteers')}
                icon={<Person />}
              />
            </Grid>
          </Grid>
        </List>
      </div>
    </>
  );
};

export default Sidebar;
